import React from "react";

function HandSvg2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="285"
      height="552"
      fill="none"
      viewBox="0 0 285 552"
    >
      <path
        stroke="#0C5DBF"
        strokeWidth="3"
        d="M71.406 613.115c26.5-39 0-215.5 0-215.5s-53-69-65.5-124 9-129 9-129v-51m0 0c0-6.921-3.654-14.251-5.035-21.5m5.035 21.5c6.198-1.167 20.176-7.1 26.5-21.5-11.2-19.393-25.69-8.08-31.535 0m91.535 21.5c-.333 9.333 14.5 33 26.5 51 5.471 8.206 1.212 26.214-3.94 39m-22.56-90c-26.5 21.5-21 14.75-36.019 79m36.019-79c-14.118-15.448-23.827-28.522-30.242-39.5m125.742 106l-21-51.75m0 0l-32-36.25c-21.167-24.834-55.3-83.1-72.5-67.5-9.675 3.349-18.907 17.554-.242 49.5m104.742 54.25c18.331 22.15 28.822 36.552 39 44.406m63.5 401.844c-16-54.5-28.167-71.334-30-122-4.167-37.667 14-113 30-159 4.348-12.5 9-7-7-60 0-39.6-23.667-52.167-35.5-53.5-8.168 0-14.521-2.345-21-7.344M65.387 172.615c-3.628-12.852-6.481-24.175-6.481-28 0-10.5 5.5-40.5 0-72.5-4.4-25.6-31.5-30.5-44-18-5.742 5.741-6.209 11.841-5.035 18m55.516 100.5c6.33 22.424 15.019 49.5 15.019 49.5s18 11.833 21 0c.167-14.667 14.5-24.5 14.5-24.5 1.879-1.091 5.116-6.693 8.06-14m-31.06-34c3.707 15 15.108 42.8 31.06 34m90.94-30.844c-.167 5.614 3.8 19.644 21 30.844 17.2 11.2 15.5 24.666 12.5 30m-155.5-181c-4.328-1.167-24.735.3-21.742 21.5"
      ></path>
    </svg>
  );
}

export default HandSvg2;