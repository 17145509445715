import React from 'react';
import './Footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer className="Footer__container">
      </footer>
    );
  }
}

export default Footer;
