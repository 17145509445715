import React from "react";

function ProjectBtn5(props) {
  return (
    <button className="Project-link" onClick={props.click}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="94"
      fill="none"
      viewBox="0 0 95 94"
    >
      <g filter="url(#filter0_d)">
        <path fill="#0C5DBF" d="M0 0H86V86H0z"></path>
        <circle cx="43.5" cy="43.5" r="30.5" fill="#fff"></circle>
        <path
          fill="#0C5DBF"
          d="M33.908 56.36c-1.536 0-2.916-.264-4.14-.792-1.224-.528-2.268-1.236-3.132-2.124a9.818 9.818 0 01-1.944-3.132 10.166 10.166 0 01-.684-3.708c0-1.32.228-2.568.684-3.744a9.402 9.402 0 011.944-3.096c.864-.912 1.908-1.632 3.132-2.16 1.224-.528 2.604-.792 4.14-.792 1.536 0 2.904.264 4.104.792 1.224.528 2.256 1.248 3.096 2.16a9.117 9.117 0 011.98 3.096c.456 1.176.684 2.424.684 3.744 0 1.296-.228 2.532-.684 3.708a9.818 9.818 0 01-1.944 3.132c-.84.888-1.872 1.596-3.096 2.124s-2.604.792-4.14.792zm-4.932-9.756c0 .84.12 1.608.36 2.304.264.672.612 1.26 1.044 1.764.456.504.984.9 1.584 1.188.6.264 1.248.396 1.944.396s1.344-.132 1.944-.396a4.69 4.69 0 001.548-1.188 5.148 5.148 0 001.044-1.8c.264-.696.396-1.464.396-2.304 0-.816-.132-1.572-.396-2.268a5.148 5.148 0 00-1.044-1.8 4.293 4.293 0 00-1.548-1.152 4.434 4.434 0 00-1.944-.432c-.696 0-1.344.144-1.944.432a5.09 5.09 0 00-1.584 1.188 6.013 6.013 0 00-1.044 1.8 6.914 6.914 0 00-.36 2.268zm26.296-2.088c1.2 0 2.316.204 3.348.612a7.578 7.578 0 012.664 1.656 7.444 7.444 0 011.728 2.592c.432 1.008.648 2.124.648 3.348 0 1.296-.24 2.472-.72 3.528a7.562 7.562 0 01-1.944 2.7c-.84.768-1.836 1.356-2.988 1.764-1.152.432-2.4.648-3.744.648-1.872 0-3.552-.396-5.04-1.188-1.464-.792-2.58-1.872-3.348-3.24l2.772-2.88a7.499 7.499 0 002.52 2.232c1.008.552 2.052.828 3.132.828 1.296 0 2.352-.384 3.168-1.152.84-.792 1.26-1.836 1.26-3.132 0-1.248-.396-2.256-1.188-3.024-.768-.768-1.752-1.152-2.952-1.152-.792 0-1.536.18-2.232.54a4.363 4.363 0 00-1.62 1.476h-4.212c0-.072.048-.336.144-.792l.36-1.8c.144-.744.3-1.56.468-2.448.168-.912.348-1.836.54-2.772.432-2.208.912-4.68 1.44-7.416h12.6v4.392h-9.108l-1.044 5.724c.288-.312.708-.564 1.26-.756.576-.192 1.272-.288 2.088-.288z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="95"
          height="94"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="5" dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0666667 0 0 0 0 0.858824 0 0 0 0 0.792157 0 0 0 0.3 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
    </button>
  );
}

export default ProjectBtn5;