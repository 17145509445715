import React from "react";

function HandSvg1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="304"
      height="561"
      fill="none"
      viewBox="0 0 304 561"
    >
      <g>
        <path
          stroke="#0C5DBF"
          strokeWidth="3"
          d="M129.563 44.5c-7.833-13.5-15-40.9 19-42.5C161.977 21.333 190.3 66.7 193.5 75.5c2.003 5.507 7.766 24.655 13.712 44.411M129.563 44.5s0-16 12.5-16m-12.5 16c0 8.5 7.5 20.23 12.5 31 3.193 6.877.269 14.146.733 31M2.259 81.91c-1.5-10 2.8-27.5 32-17.5s31.833 61.167 29.5 85.501c12.33 15.824 18.816 29.79 22.741 41.655M2.259 81.911c5.5-4.834 17.4-11.6 21 0 3.6 11.6-8.167 16.833-14.5 18m-6.5-18l6.5 18m0 0c.994 11.333 4.134 37.2 8.741 50 5.759 16-5.241 92 5.759 126s52.482 121.5 63.241 122.5C97.747 455.107 114.593 579.2 92 622m130-454.089c-2.746-7.989-8.858-28.298-14.788-48M142.796 106.5c.263 9.54 1.491 17.911 5.767 30.411 6.402 18.712 7.437 22 7.437 22-11.349 15.901-13.709 14.177-18.911 32.655m5.707-85.066c-16.917 1.833-45.803 3.211-50.796 30.411-6.241 34-5.5 20.5-5.5 54.655m0 0c5.624 17.001 5.99 29.688 10.759 37.345 3.557 5.709 0 8.5 17 8.5 5-31 17.825-26.196 20-35 1.054-4.265 1.98-7.825 2.83-10.845m70.123-71.655c7.316 9.281 22.798 32.494 32.994 48m36.294 61c0-12-3-26.5-9.5-37.345-17 0-17-8.655-17-8.655s-4.062-6.282-9.794-15m-125.947-9c-1.527 7.27.901 23.979 22.83 32.655m103.117-23.655c5.294-4 28.078 0 36.294 9.5 13.34 15.424 29.5 82.5 17 98.5l-26.5 122.5c1.667 44.863 11 141.689 35 170.089"
        ></path>
      </g>
    </svg>
  );
}

export default HandSvg1;